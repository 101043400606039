import axios from "axios"
import api from '@/axios/api.js'

export default {
    namespaced: true,
    state: {
        config: null,
        configPromise: null,
        language: null,
        languagePromise: null,
        providers: [],
        contacts: [],
        contactPromise: null
    },
    getters: {
        config: state => state.config,
        language: state => state.language,
        getProviders: state => state.providers,
        getContacts: state => state.contacts
    },
    mutations: {
        SET_PROMISE(state, promise) {
            state.configPromise = promise
        },
        SET_LANGUAGE_PROMISE(state, promise) {
            state.languagePromise = promise
        },
        SET_CONFIG(state, data) {
            state.config = data
        },
        SET_LANGUAGES(state, data) {
            state.language = data
        },
        SET_PROVIDERS(state, providers) {
            state.providers = providers
        },
        SET_CONTACTS(state, contacts) {
            state.contacts = contacts
        },
        SET_CONTACT_PROMISE(state, promise) {
            state.contactPromise = promise
        },
    },
    actions: {
        fetchConfig({ state, commit }) {
            let url = api.config;

            // Store promise in vuex to prevent multiple requests called from different components
            if (state.configPromise) {
                return state.configPromise
            }

            let configPromise = new Promise((resolve, reject) => {
                axios.get(url + '?v=' + Math.floor(Date.now() / 1000))
                    .then((response) => {
                        let data = response.data;
                        commit('SET_CONFIG', data)
                        resolve(data);
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })

            commit('SET_PROMISE', configPromise)
            return configPromise
        },
        fetchLanguages({ state, commit }) {
            let url = api.languages;
            // Store promise in vuex to prevent multiple requests called from different components
            if (state.languagePromise) {
                return state.languagePromise
            }

            let languagePromise = new Promise((resolve, reject) => {
                axios.get(url + '?v=' + Math.floor(Date.now() / 1000))
                    .then((response) => {
                        let data = response.data;
                        commit('SET_LANGUAGES', data)
                        resolve(data);
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })

            commit('SET_LANGUAGE_PROMISE', languagePromise)
            return languagePromise
        },
        fetchProviders({ commit }) {
            let url = api.providers;
            return new Promise((resolve, reject) => {
                axios.get(url + '?v=' + Math.floor(Date.now() / 1000))
                    .then((response) => {
                        let data = response.data
                        commit('SET_PROVIDERS', data)
                        resolve(data)
                    })
                    .catch(error => reject(error));
            })
        },
        fetchContacts({ state, commit }) {
            let currencyCode = process.env.VUE_APP_CURRENCY_CODE;
            let url = api.contactSettings.replace('{currencyCode}', currencyCode)
            if (state.contactPromise) {
                return state.contactPromise
            }

            let contactPromise = new Promise((resolve, reject) => {
                axios.get(url + '?v=' + Math.floor(Date.now() / 1000))
                    .then((response) => {
                        let data = response.data
                        commit('SET_CONTACTS', data)
                        resolve(data)
                    })
                    .catch(error => reject(error));
            })

            commit('SET_CONTACT_PROMISE', contactPromise)
            return contactPromise
        }
    },
}
