import Vue from 'vue'
import App from './App.vue'
import VueMobileDetection from 'vue-mobile-detection'
import store from './store'
import i18n from '@/libs/i18n'
import router from './router'

// import core styles
require('./@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')

// Composition API
Vue.use(VueMobileDetection)
import { device } from "@/libs/vue-mobile-detection"
require('@/assets/scss/' + device() + '.scss')