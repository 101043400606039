import Vue from 'vue'
import VueRouter from 'vue-router'
import { device } from "@/libs/vue-mobile-detection";

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {

    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from) {
        // If save position is true, do not scroll to top
        if (to.params.savePosition) {
            return {}
        } else if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        } else if (from.path === to.path) {
            return {}
        }
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import(/* webpackPrefetch: true */ '@/views/' + device() + '/home/HomePage.vue'),
            meta: {
                pageTitle: 'Home',
                resource: 'Auth',
            },
        },
        {
            path: '*',
            redirect: '/',
        }
    ],
})

export default router
