const CONFIG_DATA_BASE_URL = process.env.VUE_APP_DATA_BASE_URL + '/media/' + process.env.VUE_APP_MERCHANT_CODE;
const DATA_BASE_URL = process.env.VUE_APP_DATA_BASE_URL + '/media/' + process.env.VUE_APP_MERCHANT_CODE + '/' + '{currencyCode}';

export default {
    // Endpoints
    config: CONFIG_DATA_BASE_URL + '/data/config.json',
    languages: CONFIG_DATA_BASE_URL + '/data/languages.json',
    providers: CONFIG_DATA_BASE_URL + '/data/providers.json',
    contactSettings: DATA_BASE_URL + '/data/contact-settings.json',
}
