<template>
  <div id="app">
    <transition name="slide-fade" mode='out-in'>
        <component :is="layout">
            <router-view />
        </component>
    </transition>
  </div>
</template>

<script>
import { device } from "@/libs/vue-mobile-detection";
const LayoutMobile = () => import('./layouts/mobile/LayoutDesign.vue')
const LayoutDesktop = () => import('./layouts/desktop/LayoutDesign.vue')

export default {
  name: 'App',
  components: {  
    LayoutMobile,
    LayoutDesktop,
  },
  computed: {
    layout() {
      return `layout-${device()}`
    },
  },
  created() {
    // Add class to html tag
    document.documentElement.classList.add(device())
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
